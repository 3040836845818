function Footer(){
    return (
        <footer>
            <ul>
                <li><a href='https://krom.studio'>Studio Krom</a></li>
                <li><a href='mailto:support@krom.studio'>Mail</a></li>
                <li><a href='tel:+31621896780'>+316 21 89 6780</a></li>
            </ul>
        </footer>
    )
}

export default Footer;