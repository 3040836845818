import { Link } from 'react-router-dom';

function InstallationList(props){
    let installations = props.installations; 
    installations.sort((a, b) => (a.name > b.name) ? 1 : -1);
    const admin = props.name;

    if(admin == "Studio Krom"){
        let clientName = null;
        installations.sort((a, b) => (a.client > b.client) ? 1 : -1);
        let sortedInstallations = [];
        let j = 0;
        for(let i = 0; i < installations.length; i++){
            if(clientName == null){
                clientName = installations[i].client;
            }
            if(installations[i].client != clientName){
                clientName = installations[i].client;
                sortedInstallations.push(installations.slice(j, i));
                j = i;
            }
            if(i == installations.length - 1){
                sortedInstallations.push(installations.slice(j, i + 1));
            }
        }
        return(
            <div className='installationList'>
                {sortedInstallations.map((client, ulIndex) => {
                    client.sort((a, b) => (a.name > b.name) ? 1 : -1);
                    return(
                        <ul key ={ulIndex}>
                            <li className='client'>{client[0].client}</li>
                           {client.map((installation, index) => {
                                return(
                                    <li key={index}>   
                                        <Link to={"/installation/" + installation.name} state={''}>
                                            <div className='btn'>{installation.name}</div>
                                        </Link>
                                        
                                        <div className={`status ${installation.active ? "active" : ""}`}></div>
                                    </li>
                                )
                            })}
                        </ul>
                    )
                })}
            </div>
        )
    }
    else{
        return(
            <div className='installationList'>
                <ul>
                    {installations.map((installation, index) => {
                        return(
                            <li key={index}>   
                                <Link to={"/installation/" + installation.name} state={''}>
                                    <div className='btn'>{installation.name}</div>
                                </Link>
                                
                                <div className={`status ${installation.active ? "active" : ""}`}></div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default InstallationList;
