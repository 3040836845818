import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { doc, setDoc } from "firebase/firestore"; 
import db from './firebase';

function Installation(props){
    const installations = props.installations;
    const { id } = useParams();
    let installation = null;
    for(let i = 0; i < installations.length; i++){
        if(installations[i].name === id){
            installation = installations[i];
        }
    }
    const reboot = async () => {
        const docRef = doc(db, "installations", installation.id);
        await setDoc(docRef, {
            reboot: true
        }, { merge: true });
    }
    if(installation !== null){
        return(
            <div className="singleInstallation">
                <h2>{installation.name}</h2>
                <p>{installation.description}</p>
                <p>Status: <span className={`${installation.active ? "active" : ""}`}>{`${installation.active ? "online" : "offline"}`}</span></p>
                <div className={`${!installation.reboot && installation.active? "btn active" : "btn"}`} onClick={reboot}>Reboot</div>
                <Link className="back" to="/"><img src="/images/arrow.svg" />Terug</Link>
            </div>
        )
    }
    return(
        <></>
    )
}

export default Installation;