
import { useState } from 'react';
import {createUserWithEmailAndPassword} from 'firebase/auth'
import { auth } from './firebase.js';

function Signup() {
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [formMessage, setFormMessage] = useState('Create an account.');
    const [success, setSuccess] = useState(false);

    const register = async () =>{
        try{
            const userCredential = await createUserWithEmailAndPassword(auth, registerEmail, registerPassword);
            const user = userCredential.user;
            setFormMessage('Account created for ' + user.email + '. We will send you an e-mail when your account is linked to the installation backend. You can close this window.')
            setSuccess(true);
        } catch(error){
            switch(error.code){
                case 'auth/email-already-in-use':
                    setFormMessage('Email is already in use. Try another one.')
                break;
                case 'auth/invalid-email':
                    setFormMessage('Email is invalid. Please use a valid email address.')
                break;
                case 'auth/weak-password':
                    setFormMessage('Password is too weak. Use at least 6 characters.')
                break;
                default:
                    setFormMessage('Something went wrong, please contact Studio Krom.')
            }
        }
    }
    return(
        <form className="signup">
            <div className={`formWrapper ${success ? "hidden" : ""}`}>
                <h1>Sign up</h1>
                <p>{formMessage}</p>
                <input type="text" placeholder="Email" autoComplete="email" onChange={(event) => {setRegisterEmail(event.target.value)}}/>
                <input type="password" placeholder="Password" autoComplete="new-password" onChange={(event) => {setRegisterPassword(event.target.value)}}/>
                <div className="btn" onClick={register}>Create account</div>
            </div>
            <div className={`success ${!success ? "hidden" : ""}`}>
                <h1>Success</h1>
                <p>{formMessage}</p>
            </div>
        </form>
    )
}

export default Signup;

