
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from './firebase.js';
import { Navigate } from 'react-router-dom';


function Signin() {
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [formMessage, setFormMessage] = useState('Login to your account.');

    const login = async () =>{
        try{
            await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
            <Navigate to ="/" />
        }
        catch(error){
            switch(error.code){
                case 'auth/invalid-login-credentials':
                    setFormMessage('Email or password is incorrect.')
                break;
                default:
                    setFormMessage('Something went wrong, please contact Studio Krom.')
            }
        }
    }
    return(
        <form className="signup">
            <div className="formWrapper">
                <h1>Login</h1>
                <p>{formMessage}</p>
                <input type="text" placeholder="Email" autoComplete="email" onChange={(event) => {setLoginEmail(event.target.value)}}/>
                <input type="password" placeholder="Password" autoComplete="current-password" onChange={(event) => {setLoginPassword(event.target.value)}}/>
                <div className="btn" onClick={login}>Login</div>
            </div>
        </form>
    )
}

export default Signin;

