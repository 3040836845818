import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyCaxWkUIAJY3tMgxvE3GByr5jcOxv1cJYI",
    authDomain: "installation-management-system.firebaseapp.com",
    databaseURL: "https://installation-management-system-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "installation-management-system",
    storageBucket: "installation-management-system.appspot.com",
    messagingSenderId: "82323847292",
    appId: "1:82323847292:web:cfc18c745f68afd16831f9"
  };
const fireBaseApp = initializeApp(firebaseConfig);

export default getFirestore(fireBaseApp);
export const auth = getAuth(fireBaseApp);

