import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth'
import { auth } from './firebase.js';

function Header(props){

    const logout = async () =>{
        await signOut(auth);
    }

    return(
        <header>
            <Link to="">
                <h2>{props.name} <span>{props.name != "" ? "—" : ""} Installations control panel</span></h2>
            </Link>
            {props.logout != "false" ? <div className='logout' onClick={logout}>Logout</div> : ""}
        </header>
    );
}
export default Header;