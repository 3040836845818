import {Navigate, Route, Routes } from 'react-router-dom';
import db from './firebase';
import { collection, query, where, onSnapshot, getDocs} from 'firebase/firestore';

import Header from './Header'
import Installation from './Installation';
import Footer from './Footer';
import InstallationList from './InstallationList';
import Signup from './Signup';
import Signin from './Signin';
import { useEffect, useState, useRef } from 'react';
import { auth } from './firebase.js';
import { onAuthStateChanged } from 'firebase/auth'


function App() {
  const [user, setUser] = useState(null);
  const [installations, setInstallations] = useState([]);
  let unsubscribe = useRef(null);
  let userName = useRef('');

  onAuthStateChanged(auth, (user) => {
    setUser(user);
  });

  const collectUserName = async () => {
    const q = query(collection(db, "users"), where("userID", "==", user.uid));
    const querySnaps = await getDocs(q);
    querySnaps.forEach((doc) => {
      userName.current = doc.data().name;
    });
  };

  useEffect(() =>{
    if(user != null){
      collectUserName().then(() => {
        const installationsRef = collection(db, 'installations');
        let q = query(installationsRef, where('userID', '==', user.uid));
        if(userName.current === "Studio Krom"){
          q = query(installationsRef);
        }
        unsubscribe.current = onSnapshot(q, (snapshot) => {
          setInstallations(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
        })
      });
    } 
    else{
      if(unsubscribe.current != null) {
        unsubscribe.current();
        userName.current = '';
      }
    }
  }, [user]);


  if(user == null){
    return (
      <div className="app">
        <Header name="" logout="false"/>
        <div className='wrapper'>
          <Routes>
            <Route path="/signup" element = { <Signup />} />
            <Route path="*" element={<Signin />}/>
          </Routes>
          
        </div>
        <Footer/>
      </div>
    );
  }
  else{
    return (
      <div className="app">
        <Header name={userName.current} logout="true"/>
        <div className='wrapper'>
          <Routes>
              <Route path="/" element ={ <InstallationList installations = {installations}  name={userName.current} />} />
              <Route path="/installation/:id" element = { <Installation installations = {installations}/>} />
              <Route path="/signup" element = { <Signup />} />
              <Route path="/signin" element = { <Signin />} />
              <Route path="*" element={<Navigate to ="/" />}/>
          </Routes>
        </div>
        <Footer/>
      </div>
    );
  }
  
}

export default App;
